import { makeInternalAPIRequest } from 'api/makeInternalAPIRequest';
import { NetworkError } from 'api/networkError';
import { ML_DATA_PROVIDER_BASE_URL } from 'constants/globals';
import { InferenceResponse, PullInferenceResponse } from 'types/api/mldp/types';

export const fetchInference = async (
  submissionUuid: string
): Promise<InferenceResponse> => {
  const url = new URL(
    `/inference/${submissionUuid}`,
    ML_DATA_PROVIDER_BASE_URL()
  );

  const response = await makeInternalAPIRequest<InferenceResponse>(url, 'GET');

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to fetch V6 Scores');
  }

  return response.json();
};

// Note: MLDP's current authorization relies on passing a user's api_key via the X-API-KEY header.
// Data Engineering will most likely revisit this as I mentioned how this is a bit out-of-pattern
// with how our core apps handle authorization, but this should be good enough as a temporary solution.
// @mlukanus - October 3, 2024
export const pullInference = async (
  submissionUuid: string,
  apiKey = ''
): Promise<PullInferenceResponse> => {
  const url = new URL(`/inference`, ML_DATA_PROVIDER_BASE_URL());

  const response = await makeInternalAPIRequest<PullInferenceResponse>(
    url,
    'POST',
    { submission_uuid: submissionUuid }
    // TODO: Remove this if POST works without x-api-key
    // { 'X-API-KEY': apiKey }
  );

  if (!response.ok) {
    throw new NetworkError(response.status, 'Failed to pull V6 Scores');
  }

  return response.json();
};
