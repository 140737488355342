import React from 'react';
import { Box, Flex, Grid, Text } from '@forward-financing/fast-forward';
import { ColorVariant } from '@forward-financing/fast-forward/dist/__internal__/Color/colors.types';

export type Score = {
  scoreName: string;
  scoreValue?: number;
};

export type ScoresContainerBaseProps = {
  title: string;
  currentScore: number | string;
  maxScore: number | string;
  scores: Score[];
};

const scoreToBgColor = new Map<number | undefined, ColorVariant>([
  [undefined, 'gray-100'],
  [0, 'gray-100'],
  [1, 'red-100'],
  [2, 'orange-100'],
  [3, 'yellow-100'],
  [4, 'green-300'],
  [5, 'green-100'],
]);

const scoreToBorderColor = new Map<number | undefined, ColorVariant>([
  [undefined, 'gray-500'],
  [0, 'gray-500'],
  [1, 'red-500'],
  [2, 'orange-500'],
  [3, 'yellow-500'],
  [4, 'green-700'],
  [5, 'green-500'],
]);

export const ScoresContainerBase = ({
  title,
  currentScore,
  maxScore,
  scores,
}: ScoresContainerBaseProps): JSX.Element => {
  // We split the scores into chunks of 3. Each chunk will be displayed in a column.
  // This is to prevent the columns from being too long.
  const chunkedScores = [];
  for (let i = 0; i < scores.length; i += 3) {
    chunkedScores.push(scores.slice(i, i + 3));
  }

  return (
    <Box backgroundColor="gray-100" padding={1} rounded>
      <Flex flexDirection="row" gap={2} padding={2}>
        <Text size={'l'} bold>
          {title}:
        </Text>

        <Text size={'l'}>
          {currentScore} / {maxScore}
        </Text>
      </Flex>

      <Flex>
        {chunkedScores.map((chunk) => (
          <Flex
            flexDirection={'column'}
            alignContent={'center'}
            gap={2}
            padding={2}
            key={chunk.map((score) => score.scoreName).join('')}
          >
            {chunk.map((score) => (
              <Box
                key={score.scoreName}
                backgroundColor="white"
                rounded
                padding={1}
              >
                <Grid>
                  <Grid.Item xl={6}>
                    <Flex paddingTop={1}>
                      <Text bold>{score.scoreName}</Text>
                    </Flex>
                  </Grid.Item>

                  <Grid.Item xl={6}>
                    <Flex justifyContent={'end'}>
                      <Box
                        backgroundColor={scoreToBgColor.get(score.scoreValue)}
                        borderColor={scoreToBorderColor.get(score.scoreValue)}
                        py={1}
                        px={2}
                        rounded
                      >
                        <Text bold>
                          {score.scoreValue ? score.scoreValue : `N/A`}
                        </Text>
                      </Box>
                    </Flex>
                  </Grid.Item>
                </Grid>
              </Box>
            ))}
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};
